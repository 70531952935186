import { useState, useEffect, useRef } from "react";
import {
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  setDoc,
  query,
  where,
  limit,
  startAfter,
  getCountFromServer,
  GeoPoint,
  orderBy,
  startAt,
  endAt,
  getDoc,
} from "firebase/firestore";
import { db } from "../utils/firebase-config"; // Adjust the import according to your project structure
import { useNotification } from "../components/Notification";
import { useAppState } from "../appContext";
import { GeoFirestore } from "geofirestore"; // Ensure correct import path
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import moment from "moment";
import { FieldPath } from "firebase/firestore";
import { geohashQueryBounds, distanceBetween } from "geofire-common";

// Initialize GeoFirestore
const geoFirestore = new GeoFirestore(db);
const currentDate = new Date();
const fourHoursBefore = new Date(Date.now() - 4 * 60 * 60 * 1000);

const parseQueryString = (field, operator, value) => {
  switch (operator) {
    case "==":
      return where(field, "==", value);
    case "!=":
      return where(field, "!=", value);
    case "<":
      return where(field, "<", value);
    case "<=":
      return where(field, "<=", value);
    case ">":
      return where(field, ">", value);
    case ">=":
      return where(field, ">=", value);
    case "array-contains":
      return where(field, "array-contains", value);
    case "in":
      return where(field, "in", value);
    case "array-contains-any":
      return where(field, "array-contains-any", value);
    default:
      throw new Error(`Unsupported query operator: ${operator}`);
  }
};

const fetchVenueIdsByFilters = async (filters) => {
  let venueQuery = collection(db, "venues");
  filters.forEach(({ field, operator, value }) => {
    const whereClause = parseQueryString(field, operator, value);
    venueQuery = query(venueQuery, whereClause);
  });

  const venueSnapshot = await getDocs(venueQuery);
  const ids = venueSnapshot.docs.map((doc) => doc.id);
  return ids;
};

const chunkArray = (array, size) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
};

const sortEvents = (events) => {
  const now = moment().subtract(4, "hours");

  // Separate future/current events and past events
  const futureEvents = events.filter((event) => {
    if (typeof event.main_date === "string") {
      return moment(event.main_date).isSameOrAfter(now);
    } else {
      return moment(event.main_date).isSameOrAfter(now); // Or adjust based on your original logic
    }
  });
  const pastEvents = events.filter((event) => {
    if (typeof event.main_date === "string") {
      return moment(event.main_date).isBefore(now);
    } else {
      return moment(event.main_date).isBefore(now); // Or adjust based on your original logic
    }
  });

  // Sort future/current events in ascending order
  futureEvents.sort((a, b) => moment(a.main_date) - moment(b.main_date));

  // Sort past events in descending order
  pastEvents.sort((a, b) => moment(b.main_date) - moment(a.main_date));

  // Combine the sorted arrays
  return [...futureEvents, ...pastEvents];
};

const queryCache = {};

const generateCacheKey = (geoFilter, filters) => {
  const { miles, latitude, longitude } = geoFilter.value[0];
  const filterKey = filters
    ?.map((f) => `${f.field}-${f.operator}-${f.value}`)
    .join("-");
  return `${latitude}-${longitude}-${miles}-${filterKey}`;
};

const fetchGeoFilteredRecords = async (geoFilter) => {
  const cacheKey = generateCacheKey(geoFilter);
  // Check if results for this query are already cached
  if (queryCache[cacheKey]) {
    return queryCache[cacheKey];
  }

  const { miles, latitude, longitude } = geoFilter.value[0];
  const radiusInM = miles * 1609.34; // Convert miles to meters
  const center = [latitude, longitude];

  // Generate geohash query bounds
  const bounds = geohashQueryBounds(center, radiusInM);
  const matchingDocs = [];

  // Step 1: Filter by 'main_date' first
  let dateFilteredQuery = query(
    collection(db, "events"),
    where("main_date", ">=", fourHoursBefore), // Filter by main_date
    orderBy("main_date", "asc") // Must order by main_date due to inequality
  );

  const snapshot = await getDocs(dateFilteredQuery);

  // Step 2: Manually filter the results by geohash bounds
  for (const doc of snapshot.docs) {
    const geohash = doc.get("g.geohash");

    for (const b of bounds) {
      if (geohash >= b[0] && geohash <= b[1]) {
        const lat = doc.get("lat");
        const lng = doc.get("lon");

        // Filter out false positives due to GeoHash accuracy
        const distanceInKm =
          lat && lng
            ? distanceBetween([parseFloat(lat), parseFloat(lng)], center)
            : miles + 5;
        const distanceInM = distanceInKm * 1000;
        if (distanceInM <= radiusInM) {
          matchingDocs.push({
            id: doc.id,
            ...doc.data(),
          });
        }
        break; // If one bound matches, no need to check others
      }
    }
  }

  const totalCount = matchingDocs.length;
  const result = { docs: matchingDocs, totalCount, isPaginated: false };

  // Cache the result
  queryCache[cacheKey] = result;

  return result;
};

const fetchStandardFilteredRecords = async (filters, take, lastDoc, db) => {
  let orderByField = "main_date";
  const inequalities = ["<", "<=", "!=", "not-in", ">", ">="];
  let eventQuery = null;
  eventQuery = collection(db, "events");

  const hasInEquality =
    filters.filter((f) => inequalities.includes(f.operator)).length > 0;
  const hasSearchFilter =
    filters.filter((f) => f.field === "title_lowercase").length > 0;
  if (hasSearchFilter) {
    orderByField = "title_lowercase";
  }

  for (const { field, operator, value } of filters) {
    eventQuery = query(eventQuery, parseQueryString(field, operator, value));
  }
  if (!hasInEquality) {
    eventQuery = query(eventQuery, where("main_date", ">=", fourHoursBefore));
  }
  const countSnapshot = await getCountFromServer(eventQuery);

  eventQuery = query(eventQuery, orderBy(orderByField, "asc"), limit(take));
  if (lastDoc) {
    eventQuery = query(eventQuery, startAfter(lastDoc));
  }

  const querySnapshot = await getDocs(eventQuery);
  const docs = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

  const totalCount = countSnapshot.data().count;
  return { docs, totalCount, lastVisible, isPaginated: true };
};
// In-memory cache to store query results

// In-memory cache to store combined query results
// In-memory cache to store combined query results
const combinedQueryCache = {};

const generateCombinedCacheKey = (geoFilter, filters) => {
  const { miles, latitude, longitude } = geoFilter.value[0];
  const filterKey = filters
    .map((f) => `${f.field}-${f.operator}-${f.value}`)
    .join("-");
  return `${latitude}-${longitude}-${miles}-${filterKey}`;
};

const fetchCombinedFilteredRecords = async (geoFilter, filters, db) => {
  // Generate a cache key for the combined query
  const combinedCacheKey = generateCombinedCacheKey(geoFilter, filters);

  // Check if the combined results for this query are already cached
  if (combinedQueryCache[combinedCacheKey]) {
    return combinedQueryCache[combinedCacheKey];
  }

  // First, get the geospatial results using the cached fetchGeoFilteredRecords function
  const geoResults = await fetchGeoFilteredRecords(geoFilter);

  const geoDocIds = new Set(geoResults.docs.map((doc) => doc.id)); // Use a Set for faster lookup

  // Apply the standard filters and get all matching documents
  let eventQuery = collection(db, "events");
  const inequalities = ["<", "<=", "!=", "not-in", ">", ">="];
  const hasInEquality =
    filters.filter((f) => inequalities.includes(f.operator)).length > 0;

  for (const { field, operator, value } of filters) {
    eventQuery = query(eventQuery, parseQueryString(field, operator, value));
  }
  // if(!hasInEquality){
  //   eventQuery=query(eventQuery,where("main_date", ">=", new Date()))

  // }
  eventQuery = query(eventQuery, orderBy("main_date", "asc"));

  const querySnapshot = await getDocs(eventQuery);
  const allStandardFilteredDocs = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  // Intersect the standard filtered results with the geospatial results
  const matchingDocs = allStandardFilteredDocs.filter((doc) =>
    geoDocIds.has(doc.id)
  );
  const totalCount = matchingDocs.length;
  const result = { docs: matchingDocs, totalCount, isPaginated: false };

  // Cache the combined result
  combinedQueryCache[combinedCacheKey] = result;

  return result;
};

export const useFetchEvents = (
  take = 20,
  skip = 0,
  filters = [],
  enableApi = false
) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [isPaginated, setIsPaginated] = useState(true);
  const [lastDoc, setLastDoc] = useState(null); // Track the last document for pagination
  const [prevFilterString, setPrevFilterString] = useState(""); // Track previous filters

  const localEventsRef = useRef([]); // Ref to store all fetched records locally
  const isFetchingRef = useRef(false); // Ref to track if a fetch operation is in progress

  useEffect(() => {
    setLoading(true);
    if (!enableApi) {
      return;
    }
    const currentFilterString = JSON.stringify(filters);

    // Reset lastDoc and localEvents if filters have changed
    if (currentFilterString !== prevFilterString) {
      isFetchingRef.current = false;
      setLastDoc(null);
      localEventsRef.current = []; // Clear local events in the ref
      setPrevFilterString(currentFilterString); // Update the previous filter string
    }

    // Check if the local data covers the requested range
    if (localEventsRef.current.length >= skip + take) {
      setEvents(
        sortEvents(
          localEventsRef.current.slice(skip, skip + take).map((d) => {
            const mainDate = d.main_date.toDate(); // Convert Firestore Timestamp to JavaScript Date
            return {
              ...d,
              main_date: moment(mainDate).format("YYYY-MM-DD HH:mm:ss"),
              modiefieddate: moment(d.modifieddate?.toDate()).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
            };
          })
        )
      );
      setLoading(false);
      return; // No need to proceed further if local data is sufficient
    }

    // Prevent overlapping fetches using ref
    if (isFetchingRef.current) return;

    // If local data is not sufficient, fetch from Firestore
    const fetchEvents = async () => {
      setLoading(true);
      isFetchingRef.current = true; // Mark fetching as in progress
      try {
        const geospatialFilter = filters.filter(
          (f) => f.field === "coordinates"
        );
        const standardFilters = filters.filter(
          (f) => f.field !== "coordinates"
        );
        let result;

        if (geospatialFilter.length > 0 && standardFilters.length === 0) {
          // Only Geo Filter applied (using geohash)
          result = await fetchGeoFilteredRecords(
            geospatialFilter[0],
            take,
            currentFilterString !== prevFilterString ? null : lastDoc
          );
        } else if (geospatialFilter.length === 0) {
          // Only Standard Filters applied
          result = await fetchStandardFilteredRecords(
            standardFilters,
            take,
            currentFilterString !== prevFilterString ? null : lastDoc,
            db
          );
        } else if (geospatialFilter.length > 0 && standardFilters.length > 0) {
          // Combined Geo and Standard Filters applied
          result = await fetchCombinedFilteredRecords(
            geospatialFilter[0],
            standardFilters,
            db
          );
        }
        // Append new results to localEventsRef
        localEventsRef.current = [...localEventsRef.current, ...result.docs];
        // Process and set events with the necessary formatting and sorting
        setEvents(
          sortEvents(
            (result.isPaginated
              ? localEventsRef.current.slice(skip, skip + take)
              : localEventsRef.current
            ) // No slicing if not paginated
              .map((d) => {
                const mainDate = d.main_date.toDate(); // Convert Firestore Timestamp to JavaScript Date
                return {
                  ...d,
                  main_date: moment(mainDate).format("YYYY-MM-DD HH:mm:ss"),
                  modifieddate: moment(d.modifieddate?.toDate()).format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                };
              })
          )
        );

        setTotalItems(result.totalCount);
        setIsPaginated(result.isPaginated);
        setLastDoc(result.lastVisible || null);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
        isFetchingRef.current = false; // Fetching is complete
      }
    };

    fetchEvents(); // Call the fetch function if local data is insufficient
  }, [take, skip, filters, enableApi]);

  return { events, loading, totalItems, isPaginated };
};

// End new fetch evemts
export const useFetchEventsByVenueId = (venue, showPastEvents) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    setLoading(true);

    const fetchEvents = async () => {
      setLoading(true);
      try {
        const eventsCollection = collection(db, "events");
        let q;
        if (showPastEvents) {
          q = query(
            collection(db, "events"),
            where("venue", "==", venueId),
            orderBy("main_date", "asc")
          );
        } else {
          q = query(
            collection(db, "events"),
            where("venue", "==", venueId),
            orderBy("main_date", "asc"), // ✅ MUST come before where
            where("main_date", ">=", fourHoursBefore)
          );
        }

        const querySnapshot = await getDocs(q);

        const eventsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEvents(
          sortEvents(
            eventsList.map((d) => {
              const mainDate = d.main_date.toDate(); // Convert Firestore Timestamp to JavaScript Date
              let modifiedDate = null;
              if (d.modifieddate) {
                modifiedDate = d.modifieddate.toDate();
              }
              return {
                ...d,
                main_date: moment(mainDate).format("YYYY-MM-DD HH:mm:ss"),
                modifieddate: modifiedDate
                  ? moment(modifiedDate).format("YYYY-MM-DD HH:mm:ss")
                  : null,
              };
            })
          )
        );
        setTotalItems(querySnapshot.size);
      } catch (error) {
        alert("Error in fetching");
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };
    if (venue) fetchEvents();
  }, [venue, showPastEvents]);

  return { events, loading, totalItems };
};

export async function fetchEventsByVenueIdServer(venue, showPastEvents) {
  try {
    const eventsCollection = collection(db, "events");
    let q;
    if (showPastEvents) {
      q = query(
        eventsCollection,
        where("venue", "==", venue),
        orderBy("main_date", "asc")
      );
    } else {
      // Assuming fourHoursBefore is available in this scope or passed as argument
      const fourHoursBefore = new Date(Date.now() - 4 * 60 * 60 * 1000);
      q = query(
        eventsCollection,
        where("venue", "==", venue),
        where("main_date", ">=", fourHoursBefore),
        orderBy("main_date", "asc")
      );
    }

    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      main_date: moment(doc.data().main_date?.toDate()).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      modifieddate: moment(doc.data().modifieddate?.toDate()).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
    }));
  } catch (error) {
    console.error("Error fetching events:", error);
    return [];
  }
}

export async function fetchEventByIdServer(eventId) {
  try {
    const eventDocRef = doc(db, "events", eventId);
    const eventDocSnapshot = await getDoc(eventDocRef);

    if (eventDocSnapshot.exists()) {
      return { id: eventDocSnapshot.id, ...eventDocSnapshot.data() };
    } else {
      console.log("Firebase document not found.");
      return null;
    }
  } catch (error) {
    return null;
  }
}

export const useFetchEventById = (eventId) => {
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        setLoading(true);
        const eventDocRef = doc(db, "events", eventId);
        const eventDoc = await getDoc(eventDocRef);

        if (eventDoc.exists()) {
          const eventData = eventDoc.data();
          const mainDate = eventData.main_date?.toDate();
          const modifiedDate = eventData.modifieddate?.toDate();

          setEvent({
            id: eventDoc.id,
            ...eventData,
            main_date: mainDate
              ? moment(mainDate).format("YYYY-MM-DD HH:mm:ss")
              : null,
            modifieddate: modifiedDate
              ? moment(modifiedDate).format("YYYY-MM-DD HH:mm:ss")
              : null,
          });
        } else {
          setError("Event not found");
        }
      } catch (err) {
        setError(`Error fetching event: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    if (eventId) {
      fetchEvent();
    }
  }, [eventId]);

  return { event, loading, error };
};

export const useFetchEventsByBandId = (band, showPastEvents) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    setLoading(true);

    const fetchEvents = async () => {
      setLoading(true);
      try {
        const eventsCollection = collection(db, "events");
        let q;
        if (showPastEvents) {
          q = query(
            eventsCollection,
            where("bandIds", "array-contains", band),
            orderBy("main_date", "asc")
          );
        } else {
          q = query(
            eventsCollection,
            where("bandIds", "array-contains", band),
            where("main_date", ">=", fourHoursBefore),
            orderBy("main_date", "asc")
          );
        }

        const querySnapshot = await getDocs(q);

        const eventsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEvents(
          sortEvents(
            eventsList.map((d) => {
              const mainDate = d.main_date.toDate(); // Convert Firestore Timestamp to JavaScript Date
              // const formattedMainDate = formatDate(mainDate);
              return {
                ...d,
                main_date: moment(mainDate).format("YYYY-MM-DD HH:mm:ss"),
                modifieddate: moment(d.modifieddate?.toDate()).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
              };
            })
          )
        );
        setTotalItems(querySnapshot.size);
      } catch (error) {
        alert("Error in fetching");
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };
    if (band) fetchEvents();
  }, [band, showPastEvents]);

  return { events, loading, totalItems };
};

export const useFetchPageByInternalLink = (internallink) => {
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPage = async () => {
      if (!internallink) {
        setError("Internal link is required");
        return;
      }

      try {
        setLoading(true);
        const pagesRef = collection(db, "pages");
        const q = query(
          pagesRef,
          where("internallink", "==", `/${internallink.toLowerCase()}/`)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const pageDoc = querySnapshot.docs[0]; // Assuming internallink is unique
          setPage({
            id: pageDoc.id,
            ...pageDoc.data(),
          });
          return { id: pageDoc.id, ...pageDoc.data() };
        } else {
          setError("Page not found");
        }
      } catch (err) {
        setError(`Error fetching page: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };
    fetchPage();
  }, [internallink]);

  return { page, loading, error };
};
