import { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  setDoc,
  getCountFromServer,
  getDoc,
} from "firebase/firestore";
import { db } from "@/utils/firebase-config";
import { useAppState } from "../appContext";
import { useNotification } from "../components/Notification";
import { query, where, orderBy, limit, startAfter } from "firebase/firestore";

const bandsCache = {};

const generateBandsCacheKey = (searchTerms, skip, type, take) => {
  const searchKey = searchTerms?.join("-") || "all";
  return `${searchKey}-${skip}-${type}-${take}`;
};

export const useFetchBands = (enableApi = true) => {
  const [bands, setBands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [state, dispatch] = useAppState();

  useEffect(() => {
    const fetchBands = async () => {
      if (state?.fetchedBands?.length > 0) {
        setBands(state?.fetchedBands);
        setLoading(false);
        return;
      }
      setLoading(true);
      const cacheKey = generateBandsCacheKey([], 0, 0, enableApi);
      if (bandsCache[cacheKey]) {
        const cachedResult = bandsCache[cacheKey];
        setBands(cachedResult.bands);
        setLoading(false);
        return;
      }
      try {
        let bandsQuery = collection(db, "bands");
        bandsQuery = query(bandsQuery, orderBy("title_lowercase", "asc"));

        const querySnapshot = await getDocs(bandsQuery);
        const fetchedBands = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        bandsCache[cacheKey] = {
          bands: fetchedBands,
        };
        setBands(fetchedBands);
        dispatch({
          type: "SET_PROP",
          payload: { key: "fetchedBands", value: fetchedBands },
        });
        setLoading(false);
      } catch (err) {
        console.error("Error fetching bands:", err);
        setError(err.message);
        setLoading(false);
      }
    };
    if (enableApi === false && bands.length > 0) {
      return;
    } else if (enableApi) fetchBands();
  }, [enableApi]);

  return { bands, loading, error };
};
export const useFetchBandById = (bandId) => {
  const [band, setBand] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBand = async () => {
      try {
        setLoading(true);
        const bandDocRef = doc(db, "bands", bandId);
        const bandDoc = await getDoc(bandDocRef);

        if (bandDoc.exists()) {
          setBand({
            id: bandDoc.id,
            ...bandDoc.data(),
          });
        } else {
          setError("Band not found");
        }
      } catch (err) {
        setError(`Error fetching band: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    if (bandId) {
      fetchBand();
    }
  }, [bandId]);

  return { band, loading, error };
};

export const useFetchBandByInternalLink = (internallink) => {
  const [band, setBand] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBand = async () => {
      try {
        setLoading(true);
        const bandsRef = collection(db, "bands");
        const q = query(
          bandsRef,
          where("internallink", "==", `/${internallink.toLowerCase()}/`)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const bandDoc = querySnapshot.docs[0]; // Assuming internallink is unique
          setBand({
            id: bandDoc.id,
            ...bandDoc.data(),
          });
        } else {
          setError("Band not found");
        }
      } catch (err) {
        setError(`Error fetching band: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    if (internallink) {
      fetchBand();
    }
  }, [internallink]);

  return { band, loading, error };
};
