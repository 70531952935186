import { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  setDoc,
  getCountFromServer,
  getDoc,
} from "firebase/firestore";
import { db } from "../utils/firebase-config";
import { useAppState } from "../appContext";
import { useNotification } from "../components/Notification";
import { query, where, orderBy, limit, startAfter } from "firebase/firestore";

// Custom hook to fetch all venues from Firestore
export const useFetchVenuesOld = () => {
  const [venues, setVenues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [state, dispatch] = useAppState();

  useEffect(() => {
    const fetchVenues = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "venues"));
        const venuesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setVenues(venuesData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching venues:", error);
        setLoading(false);
      }
    };
    const venuesData = localStorage.getItem("venues");
    if (venuesData && JSON.parse(venuesData).length) {
      setVenues(JSON.parse(venuesData));
      setLoading(false);
    } else fetchVenues();
  }, []);

  return { venues, loading };
};

const venuesCache = {};

const generateVenuesCacheKey = (searchTerms, skip, type, take) => {
  const searchKey = searchTerms?.join("-") || "all";
  return `${searchKey}-${skip}-${type}-${take}`;
};

// Server-Side Data Fetching Function

export async function fetchVenueByIdServer(venueId) {
  try {
    const venueDocRef = doc(db, "venues", venueId);
    const venueDocSnapshot = await getDoc(venueDocRef);

    if (venueDocSnapshot.exists()) {
      const venueData = venueDocSnapshot.data();
      return { id: venueDocSnapshot.id, ...venueDocSnapshot.data() };
    } else {
      return null; // Venue not found
    }
  } catch (error) {
    console.error("Error fetching venue data:", error);
    return null;
  }
}

export const useFetchVenuesWithSearch = (
  take = 20,
  skip = 0,
  searchTerms = [],
  type = "accordion"
) => {
  const [venues, setVenues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [lastDoc, setLastDoc] = useState(null); // Track the last document for pagination
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVenues = async () => {
      setLoading(true);

      try {
        const cacheKey = generateVenuesCacheKey(searchTerms, skip, type, take);
        // Check if results for this query are already cached
        if (venuesCache[cacheKey]) {
          const cachedResult = venuesCache[cacheKey];
          setVenues(cachedResult.venues);
          setTotalItems(cachedResult.totalItems);
          setLastDoc(cachedResult.lastDoc);
          setLoading(false);
          return;
        }

        let venuesQuery = collection(db, "venues");

        if (searchTerms.length > 0) {
          venuesQuery = query(
            venuesQuery,
            where("searchTerms", "array-contains-any", searchTerms)
          );
        }
        const countSnapshot = await getCountFromServer(venuesQuery);

        const totalCount = countSnapshot.data().count;

        venuesQuery = query(
          venuesQuery,
          orderBy("title_lowercase", "asc"),
          limit(take)
        );

        if (skip > 0 && lastDoc) {
          venuesQuery = query(venuesQuery, startAfter(lastDoc));
        }

        const querySnapshot = await getDocs(venuesQuery);
        const fetchedVenues = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

        // Get total count of items
        // const totalCountSnapshot = await getDocs(query(venuesQuery));
        // const totalCount = totalCountSnapshot.size;

        // Cache the result
        venuesCache[cacheKey] = {
          venues: fetchedVenues,
          totalItems: totalCount,
          lastDoc: lastVisible,
        };

        setVenues(fetchedVenues);
        setTotalItems(totalCount);
        setLastDoc(lastVisible);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching venues:", err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchVenues();
  }, [take, skip, searchTerms, type]);

  return { venues, loading, totalItems, error };
};
export const useFetchVenues = (enableApi = true) => {
  const [venues, setVenues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [state, dispatch] = useAppState();

  useEffect(() => {
    const fetchVenues = async () => {
      if (state?.fetchedVenues?.length > 0) {
        setVenues(state?.fetchedVenues);
        setLoading(false);
        return;
      }
      setLoading(true);
      const cacheKey = generateVenuesCacheKey([], 0, 0, enableApi);
      if (venuesCache[cacheKey]) {
        const cachedResult = venuesCache[cacheKey];
        setVenues(cachedResult.venues);

        setLoading(false);
        return;
      }
      try {
        let venuesQuery = collection(db, "venues");
        venuesQuery = query(venuesQuery, orderBy("title_lowercase", "asc"));

        const querySnapshot = await getDocs(venuesQuery);
        const fetchedVenues = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        venuesCache[cacheKey] = {
          venues: fetchedVenues,
        };
        setVenues(fetchedVenues);
        dispatch({
          type: "SET_PROP",
          payload: { key: "fetchedVenues", value: fetchVenues },
        });
        setLoading(false);
      } catch (err) {
        console.error("Error fetching venues:", err);
        setError(err.message);
        setLoading(false);
      }
    };
    if (enableApi === false && venues.length > 0) {
      return;
    } else if (enableApi) fetchVenues();
  }, [enableApi]);

  return { venues, loading, error };
};
export const useFetchVenueById = (venueId) => {
  const [venue, setVenue] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVenue = async () => {
      try {
        setLoading(true);
        const venueDocRef = doc(db, "venues", venueId);
        const venueDoc = await getDoc(venueDocRef);

        if (venueDoc.exists()) {
          setVenue({
            id: venueDoc.id,
            ...venueDoc.data(),
          });
        } else {
          setError("Venue not found");
        }
      } catch (err) {
        setError(`Error fetching venue: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    if (venueId) {
      fetchVenue();
    }
  }, [venueId]);

  return { venue, loading, error };
};

export default useFetchVenueById;

export const useFetchUtilityData = () => {
  const [utilityData, setUtilityData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUtilityData = async () => {
      try {
        const utilityRef = db.collection("utility").doc("1");
        const docSnapshot = await utilityRef.get();
        if (docSnapshot.exists) {
          const data = docSnapshot.data();
          const { cities, ...rest } = data; // Destructure cities
          setUtilityData(rest); // Set the rest of the data
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUtilityData();
  }, []);

  return { utilityData, loading, error };
};
export const useFetchVenueByInternalLink = (internallink) => {
  const [venue, setVenue] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVenue = async () => {
      try {
        setLoading(true);
        const venuesRef = collection(db, "venues");
        const q = query(
          venuesRef,
          where("internallink", "==", `/${internallink.toLowerCase()}/`)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const venueDoc = querySnapshot.docs[0]; // Assuming internallink is unique
          setVenue({
            id: venueDoc.id,
            ...venueDoc.data(),
          });
        } else {
          setError("Venue not found");
        }
      } catch (err) {
        setError(`Error fetching venue: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    if (internallink) {
      fetchVenue();
    }
  }, [internallink]);

  return { venue, loading, error };
};
